<template>
  <div>
    <div class="top-info-bar py-1">
      <div class="container">
        <div class="row">
          <div class="col-6 text-start" v-if="diner">
            <!-- <strong> Jméno strávníka:</strong> -->
            <span class="fs-4">{{ diner.full_name }} </span> <br />

            <!-- <strong>ID strávníka: </strong> -->
            <span class="text-muted top-info-bar-user-id"
              ><span class="fw-lighter">ID:</span> {{ diner.diner_number }}
            </span>
          </div>
          <div class="col-6 text-end d-flex flex-column flex-md-row justify-content-sm-start justify-content-md-end gap-1 align-items-sm-center gap-md-4" v-if="diner">
            <div class="diner-ammount">
              <span class="fw-light text-muted">{{ $t('diner.conto_status') }}</span> <br />
              <span class="fs-4 fw-bolder text-success" v-if="diner.amount > 0">{{ formatPrice(diner.amount) }} Kč</span>
              <span class="fs-4 fw-bolder text-danger" v-else>{{ formatPrice(diner.amount) }} Kč</span>
              <br />
            </div>

            <div class="top-info-bar-logout-button">
              <button class="btn btn-danger btn-bold" @click="onLogout">
                {{ $t("button.logout") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/services/api.service";
import { LOGOUT } from "@/store/index";

export default {
  name: "Topbar",
  data() {
    return {
      loading: false,
      diner: null,
    };
  },
  mounted() {
    this.getDiner();
  },
  methods: {
    getDiner() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet("diner/info").then((response) => {
        this.diner = response.data.data;
        this.loading = false;
      });
    },
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },
    formatPrice(value) {
        let val = (value/1).toFixed(0).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    }
  },
};
</script>
