<template>
  <div>
    <Header></Header>
    <Topbar></Topbar>
    <router-view/>
    <Footer></Footer>
  </div>
</template>

<style lang="scss">
  // Module styles
  @import "assets/sass/style";
</style>

<script>
import Header from "@/views/core/Header";
import Topbar from "@/views/core/Topbar";
import Footer from "@/views/core/Footer";

export default {
  components: {
    Header, Topbar, Footer
  }
};
</script>